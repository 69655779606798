import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { RequestService, LayoutUtilsService } from '../../../shared/services';
import { FormControl, FormGroupDirective, NgForm, FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

export class My2ErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss']
})
export class CustomSelectComponent implements OnInit {
  public errorMessage: string = '';
  public loading: boolean = false;
  selectData: Array<any> = [];
  /* pagination Info */
  pageSize = 100;
  pageNumber = 1;
  orderDir = 'asc';
  orderBy = 'name';
  searchText = '';

  myControl = new FormControl();
  filteredData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  public hideBecauseOne: boolean = false;
  private _parentFormSubmitted = false;
  @Input() clearable: boolean = false;
  @Input() required: boolean = false;
  @Input() hideIfOne: boolean = false;
  public esMatcher = new My2ErrorStateMatcher();
  @Input() displayName: string[] = ['name', 'text'];
  @Input() filters: any;
  @Input() itemName: string = '';
  @Input() placeholder: string = '';
  @Input() dataType: string;
  @Input() subDataType: string;
  @Input() apiTarget: string = 'list';
  @Input() value: any;
  @Input() conditionalDisable: boolean = false;
  // @Input() value: any = {_id: '', name: ''};
  // @Input()
  // set childValue(childValue: any){
  //   if (childValue && childValue.hasOwnProperty('_id')) {
  //     this.value = childValue['_id'];
  //   }else {
  //     this.value = undefined;
  //   }
  // }
  // get childValue(): any{
  //   return this.value;
  // }
  @Input()
  set parentFormSubmitted(parentFormSubmitted: boolean) {
    this._parentFormSubmitted = parentFormSubmitted;
    if (parentFormSubmitted) {
      if (this.form)
        this.form.onSubmit(undefined);
    }
  }
  get parentFormSubmitted(): boolean {
    return this._parentFormSubmitted;
  }
  @Output() onSelectReturn = new EventEmitter<any>();
  @Output() onRoleSelectReturn = new EventEmitter<any>();
  @ViewChild('dataForm') form: NgForm;
  constructor(private translate: TranslateService,
    private requestService: RequestService,
    private layoutUtilsService: LayoutUtilsService
  ) { }
  ngAfterViewInit() {
    if (this.parentFormSubmitted) {
      if (this.form)
        this.form.onSubmit(undefined);
    }
  }
  ngOnInit() {
    this.loadData();
  }
  public onSubmit() {
    // do nothing
  }
  private getSelectedItem(val) {
    for (let itm of this.selectData) {
      if (val === itm._id) {
        return itm
      }
    }
    return '';
  }
  public setAttribute(val) {
    let valT = undefined;
    if (this.apiTarget === 'search') {
      if (val)
        valT = val._id;
      if (this.dataType === 'resourcetypes') {
        let selectedItem = this.getSelectedItem(valT);
        this.onRoleSelectReturn.emit(selectedItem);
      } else {
        this.onSelectReturn.emit(valT);
      }
    } else if (this.apiTarget === 'searchObject') {
      if (val)
        this.onSelectReturn.emit(val);
    } else {
      if (val)
        valT = val.value;
      if (this.dataType === 'resourcetypes') {
        let selectedItem = this.getSelectedItem(valT);
        this.onRoleSelectReturn.emit(selectedItem);
      } else {
        this.onSelectReturn.emit(valT);
      }
    }
  }
  displayFn(data?: any): string | undefined {
    return data ? data.name : undefined;
  }
  public loadData() {
    if (this.dataType == 'tile' && this.apiTarget === 'summary') {
      this.loadTileDataSearch();
    } else if (this.dataType == 'qascore' && this.apiTarget === 'summary') {
      this.loadDataSearch();
    } else if (this.apiTarget === 'searchObject') {
      this.loadDataSearch();
    } else if (this.apiTarget === 'search') {
      this.loadDataListOrg();
    } else if (this.apiTarget === 'list/organization') {
      this.loadDataListOrg();
    } else if (this.apiTarget === 'list/application') {
      this.loadDataListApp();
    } else {
      this.loadDataList();
    }
  }
  public loadTileDataSearch() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let filterConfiguration = this.filters;
      this.requestService.getDataListSummary(this.dataType, { page: this.pageNumber, term: this.searchText, perpage: -1, filter: filterConfiguration }, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          this.selectData = data.results.results;
        } else {
          this.selectData = [];
        }
        if (this.hideIfOne && this.selectData.length === 1) {
          this.hideBecauseOne = true;
          // this.setAutoOne(this.selectData[0]._id);
        }
        this.filteredData.next(this.selectData);
        this.loading = false;
      });
    }
  }
  public loadDataList() {

    let isCached = true;
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';

      if (this.dataType == "diagnostic") {
        isCached = false;
      }
      this.requestService.getDataL(this.dataType, (data, error) => {
        if (error) {
          this.errorMessage = error;
        }
        if (data) {
          this.selectData = data.results;
        } else {
          this.selectData = [];
        }
        if (this.hideIfOne && this.selectData.length === 1) {
          this.hideBecauseOne = true;
        }
        this.filteredData.next(this.selectData);
        this.loading = false;
      }, isCached, undefined, undefined, this.subDataType);
    }
  }
  public loadDataListApp() {

    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.getDataL(this.dataType, (data, error) => {
        if (error) {
          this.errorMessage = error;
        }
        if (data) {
          this.selectData = data.results;
        } else {
          this.selectData = [];
        }
        if (this.hideIfOne && this.selectData.length === 1) {
          this.hideBecauseOne = true;
        }
        this.filteredData.next(this.selectData);
        this.loading = false;
      }, false, this.requestService.appId);
    }
  }
  public loadDataListOrg() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.getDataLByOrg(this.dataType, (data, error) => {
        if (error) {
          this.errorMessage = error;
        }
        if (data) {
          this.selectData = data.results;
        } else {
          this.selectData = [];
        }
        if (this.hideIfOne && this.selectData.length === 1) {
          this.hideBecauseOne = true;
        }
        this.filteredData.next(this.selectData);
        this.loading = false;
      });
    }
  }
  public loadDataSearch() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let filterConfiguration = this.filters;
      this.requestService.getDataListSummary(this.dataType, { page: this.pageNumber, term: this.searchText, perpage: this.pageSize, filter: filterConfiguration, fieldKeys: ['name'] }, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          this.selectData = data.results;
        } else {
          this.selectData = [];
        }
        if (this.hideIfOne && this.selectData.length === 1) {
          this.hideBecauseOne = true;
        }
        this.filteredData.next(this.selectData);
        this.loading = false;
      });
    }
  }
  termSearch(e): any {
    this.searchText = e.term;
    this.loadDataSearch();
  }
}
