import { Component, OnInit, ViewChild, OnDestroy, ElementRef, Input, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
// import { ModalGalleryDialogComponent } from '../custom-gallery-dialog/custom-gallery-dialog.component';
import { TilesListComponent } from '../tiles-list/tiles-list.component';
import { ModalPreviewDialogComponent } from '../custom-preview-dialog/custom-preview-dialog.component';
import { ModalTileDialogComponent } from '../custom-tile-dialog/custom-tile-dialog.component';
import { FormUtils } from '../../helpers/utils';
import { FormLoaderService, FormLayoutUtilsService, FormRequestService } from '../../services';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { BlockOrganizer, BlockComponent, BlockItem, GetBlocks } from '../widgets/block-organizer';

class MyUserErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-tile-data',
  templateUrl: './tile-data.component.html',
  styleUrls: ['./tile-data.component.scss']
})
export class TileDataComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  // public selectedUser: any;
  // public isAdmin: boolean = false;
  public originalTableSetting: any = undefined;
  public tableSetting: any = undefined;
  public errorMessage: string = '';
  public filterObject: any = undefined;
  public scopes: string[] = [];
  public defaultFields: string[] = ['title', 'flag'];
  public fieldsObject: string[] = JSON.parse(JSON.stringify(this.defaultFields));

  public filterStored = { '$and': [] };

  public classId: string = undefined;
  public lectureId: string = undefined;
  public selectedIndex: number = undefined;

  public canExport: boolean = true;
  public canFilter: boolean = true;
  public loading: boolean = false;
  public tableName: string = 'tile';
  public dataType: string = 'tile';
  public dataTypeDisplay: string = this.translate.instant('Library');
  public UIDisplay: string = this.translate.instant('Content');
  public filterType: string;
  public fieldsToHide: string[] = [];
  public targetName: string = 'tiles';

  public selectedOrganization: string = "-1";
  public selectedLanguage: string = "en";
  public organizations: any[] = [];
  public blocks: any[] = [];
  public currentSelectedBlocks: any[] = [];
  public originalBlocksData: any[] = [];
  public tileIdsDelete: string[] = [];
  public tileIdsUpdate: Object = {};
  public tileBlocks: any[] = [];
  public profileDatas: any[] = [];
  public tileCategories: any[] = [];
  public widgetCategories: any[] = [];
  public tileThemes: any[] = [];
  public widgetRights: any[] = [];
  public rtl: boolean = false;
  public defaultThemeId: string = "-1";
  public orgTileCategory: string = "-1";
  public template: string = "-1";
  public startWrapper: boolean = false;
  public esMatcher = new MyUserErrorStateMatcher();

  public paginatorTotal: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public selectedDataEmpty: any = {
    title: '',
    notes: '',
    language: 'en',
    category: '-1',
    type: 'content',
    template: this.defaultThemeId,
    rtl: false,
    requiresLogin: false,
    enableZoom: false,
    showAsTabs: false,
    blocksData: [],
    blocks: [],
    organizationId: [],
    createdOrg: undefined,
    isRoleBased: false,
    userName: '',
    lastUpdatedUser: '',
    art: '/assets/img/tile_default.jpg',
    flag: ''
  };

  @Input() hasFlagType: boolean = false;
  public flagMeta: any = undefined;
  public selectedDataKeysType: any = {
    title: { name: 'title', displayName: 'title', type: 'string', valueType: 'string', check: true },
    // notes: {name: 'notes', displayName: 'Notes',type: 'string', valueType: 'string', check: true},
    // language: {name: 'language', displayName: 'Language',type: 'list', valueType: 'id', check: true},
    category: { name: 'category', displayName: 'Category', type: 'list', valueType: 'id', check: true },
    flag: { name: 'flag', displayName: 'Flag', type: 'string', valueType: 'string', check: this.hasFlagType },
    // type: {name: 'type', displayName: 'Type',type: 'string', valueType: 'string', check: true},
    // template: {name: 'template', displayName: 'Template',type: 'list', valueType: 'id', check: true},
    // rtl: { name: 'rtl', displayName: 'Rtl', type: 'boolean', valueType: 'boolean', check: true },
    // requiresLogin: {name: 'requiresLogin', displayName: 'Requires Login',type: 'boolean', valueType: 'boolean', check: true},
    // enableZoom: {name: 'enableZoom', displayName: 'Enable Zoom',type: 'boolean', valueType: 'boolean', check: true},
    // showAsTabs: {name: 'showAsTabs', displayName: 'show As Tabs',type: 'boolean', valueType: 'boolean', check: true},
    blocksData: { name: 'blocksData', displayName: 'Blocks Data', type: 'array', valueType: 'arrayblocks', check: true },
    // blocks: {name: 'blocks', displayName: 'Blocks',type: 'array', valueType: 'array', check: true},
    // organizationId: {name: 'organizationId', displayName: 'Organization Id', type: 'array', valueType: 'array', check: true},
    // createdOrg: {name: 'createdOrg', displayName: 'Created Org', type: 'id', valueType: 'id', check: true},
    // art: { name: 'art', displayName: 'Art', type: 'image', valueType: 'string', check: true },
    // userName: {name: 'userName', displayName: 'User Name', type: 'string', valueType: 'string', check: true},
    // lastUpdatedUser: {name: 'lastUpdatedUser', displayName: 'LastUpdatedUser', type: 'string', valueType: 'string', check: true},
  };
  public selectedDataKeysTypeList = Object.keys(this.selectedDataKeysType);

  public selectedOriginalData: any = JSON.parse(JSON.stringify(this.selectedDataEmpty));
  public selectedData: any = JSON.parse(JSON.stringify(this.selectedDataEmpty));

  public selectedUser: any;
  @Input() showSelfLoader: boolean = true;
  @Input() isAdmin: boolean = false;
  @Input() isSuperAdmin: boolean = false;
  public collapseOnload: boolean = false;
  public appId: string;
  public locId: string;
  @Input() enableFormWraps: boolean = false;
  @Input() isDialog: boolean = false;
  @Input() getTileDB: boolean = false;
  @Output() onSaveReturn = new EventEmitter<any>();
  public searchVal: string = '';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // orderByList = [{field: 'firstName',order: 'asc'}];

  // @ViewChild('customMetadataTable') custom_metadata_table: CustomMetadataTableComponent;
  @ViewChild('tileList') tile_list: TilesListComponent;
  @ViewChild('title') title: ElementRef;
  constructor(
    private formRequestService: FormRequestService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private layoutUtilsService: FormLayoutUtilsService,
    private utils: FormUtils,
    public dialog: MatDialog, private loaderService: FormLoaderService, private translate: TranslateService) { }

  ngOnInit() {
    this.subscriptions.push(
      this.formRequestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.selectedOrganization = this.formRequestService.orgId;
          this.appId = this.formRequestService.appId;
          this.locId = this.formRequestService.locId;
          this.selectedDataEmpty['organizationId'] = [this.selectedOrganization];
          this.selectedDataEmpty['createdOrg'] = this.selectedOrganization;
          this.selectedData = JSON.parse(JSON.stringify(this.selectedDataEmpty));
          // this.getOrganizations();
          this.organizations = [this.formRequestService.pageOrganization.getValue()];
          if (this.hasFlagType) {
            this.buildSetting();
          } else {
            this.setWidgetRights();
          }
        }
      })
    );
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  toggleClick(action) {
    if (action === 'new') {
      this.newData();
    } else if (action === 'delete') {
      this.tile_list.delete(undefined, this.selectedData['_id']);
    } else if (action === 'duplicate') {
      this.duplicate();
    } else if (action === 'save') {
      this.save();
    } else if (action === 'Newpreview') {
      this.Newpreview();
    } else if (action === 'preview') {
      this.preview();
    } else if (action === 'background') {
      // this.background();
    } else if (action === 'icon') {
      this.icon();
    } else if (action === 'designer') {
      this.router.navigate(['/rooms/settings'], { relativeTo: this.activatedRoute });
    }
  }
  tileActionReturn(actions) {
    if (actions.action === 'widgetTileReset') {
      this.widgetTileReset(actions.val1, actions.val1);
    }
    this.widgetTileReset(true, true);
  }
  widgetTileReset(isNew?: boolean, isBlocks?: boolean, isUpdate?: boolean) {

    this.selectedData.category = '-1'

    this.collapseOnload = false;
    if (isNew) {
      this.selectedData = JSON.parse(JSON.stringify(this.selectedDataEmpty));
      this.selectedOriginalData = JSON.parse(JSON.stringify(this.selectedData));
      this.originalBlocksData = [];
    }

    if (isBlocks) {
      this.blocks = [];
    }
    if (!isUpdate) {
      this.selectedLanguage = "en";
      this.startWrapper = false;
    }

    // this.tileIdsDelete = [];
  };
  dataChanged() {
    for (let ky of this.selectedDataKeysTypeList) {
      if (this.selectedDataKeysType[ky].check === true) {
        let val1 = this.selectedOriginalData[ky];
        let val2 = this.selectedData[ky];
        if (this.selectedDataKeysType[ky].valueType === 'array') {
          if (val1.length !== val2.length) {
            return true;
          }
        } else if (this.selectedDataKeysType[ky].valueType === 'arrayblocks') {
          if (this.originalBlocksData.length === this.blocks.length) {
            for (let blkData of this.blocks) {
              if (blkData.hasOwnProperty("_id")) {
                let newBlockData = Object.assign({}, this.getBlockData(blkData));
                let oldBlockData = Object.assign({}, this.getBlockData(blkData["existingData"]));
                let result = this.utils.compareObj(newBlockData, oldBlockData);
                if (!result) {
                  return true;
                }
              } else {
                return true;
              }
            }
          } else {
            return true;
          }
        } else {
          if (val1 !== val2) {
            return true;
          }
        }
      }
    }
    return false;
  }
  getBlockData(blockData) {
    let blobObj = {
      _id: blockData["_id"],
      data: blockData["data"],
      type: blockData["type"],
      activate: blockData["activate"],
      version: blockData["version"],
      isForm: blockData["isForm"]
    };
    return blobObj;
  }
  newData() {
    if (this.dataChanged()) {
      this.newTile('Would you like to save your previous work?', 'Save', 'Discard', (value) => {
        if (value === 'Save') {
          this.tileSave("", true, false, false, this.selectedData, false, undefined, false, undefined, true);
        } else if (value === 'Discard') {
          this.widgetTileReset(true, true);
        }
      });
    } else {
      this.widgetTileReset(true, true);
    }
  }
  duplicate() {
    let currTileExists: any = !this.utils.isEmptyObject(this.selectedData) ? Object.assign({}, this.selectedData) : {};
    if (!this.utils.isEmptyObject(currTileExists) && currTileExists.hasOwnProperty("_id")) {
      if (this.dataChanged()) {
        this.newTile('Would you like to save your work and duplicate?', 'Yes', 'No', (value) => {
          if (value === 'Yes') {
            this.tileSave("", true, true);
          } else if (value === 'No') {
            this.tileSave("", false, true);
          }
        });
      } else {
        this.tileSave("", false, true);
      }
    }
  }
  selectItemCheck(data) {
    let currTileExists: any = !this.utils.isEmptyObject(this.selectedData) ? Object.assign({}, this.selectedData) : {};
    if (!this.utils.isEmptyObject(currTileExists)) { //&& currTileExists.hasOwnProperty("_id")
      if (this.dataChanged()) {
        this.newTile('Would you like to save your work and select?', 'Yes', 'No', (value) => {
          if (value === 'Yes') {
            this.tileSave("", true, false, false, undefined, false, undefined, true, data);
          } else if (value === 'No') {
            this.selectItem(data);
          }
        });
      } else {
        this.selectItem(data);
      }
    } else {
      this.selectItem(data);
    }
  }
  selectItem(targetData, langCode: string = 'en') {
    if (this.getTileDB) {
      if (!this.loading) {
        this.loading = true;
        this.loaderService.display(true);
        this.errorMessage = '';
        this.formRequestService.getSingleDataOriginal(this.dataType, targetData._id, (data, error) => {
          if (error) {
            this.errorMessage = error;
            this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
          }
          this.loaderService.display(false);
          this.loading = false;
          if (data) {
            if (data.results) {
              this.selectDBItem(data.results, langCode);
            }
          }
        });
      }
    } else {
      this.selectDBItem(targetData, langCode);
    }
  }
  selectDBItem(data, langCode: string = 'en') {
    let newD = { ...data };
    if (typeof (data.category) == 'object') {
      newD.category = data.category._id;
    } else {
      newD.category = data.category;
    }
    if (!newD.hasOwnProperty('flag')) {
      newD.flag = '';
    }
    data = newD;
    this.selectedData = JSON.parse(JSON.stringify(data));
    this.selectedOriginalData = JSON.parse(JSON.stringify(data));
    this.originalBlocksData = JSON.parse(JSON.stringify(this.selectedData.blocksData));
    if (this.selectedData['organizationId'].indexOf(this.selectedOrganization) === -1) {
      this.selectedData['organizationId'] = [this.selectedData['organizationId']];
    } else {
      if (typeof (this.selectedData['organizationId']) == 'string') {
        this.selectedData['organizationId'] = [this.selectedData['organizationId']];
      }
    }
    this.blocks = [];
    this.selectedData.blocks = []; // added this so i don't alter the blocks
    this.startWrapper = false;
    this.collapseOnload = true;
    if (this.selectedData.blocksData.length > 0) {
      for (let i = 0; i < this.selectedData.blocksData.length; i++) {
        let currentBlock: any = this.selectedData.blocksData[i];
        let preCollapseStatus = this.currentSelectedBlocks && this.currentSelectedBlocks[i] && this.currentSelectedBlocks[i].hasOwnProperty('collapse') && this.currentSelectedBlocks[i]._id === currentBlock['_id'] ? this.currentSelectedBlocks[i].collapse : this.collapseOnload;
        let type: string = this.selectedData.blocksData[i].hasOwnProperty("type") ? this.selectedData.blocksData[i].type : "";
        this.loadWidgets("", type, currentBlock, "", preCollapseStatus);
      }
    }
    this.selectedLanguage = langCode;
  }

  public removeWidget(e, idx: any) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.blocks.splice(idx, 1);
    if (this.blocks.length === 0 || (this.selectedIndex && this.blocks.length > 0 && this.selectedIndex > this.blocks.length - 1)) {
      this.selectedIndex = undefined;
    }
  }
  public deleteWidget(idx: any) {
    this.blocks[idx]['activate'] = false;
  }
  public unDeleteWidget(idx: any) {
    this.blocks[idx]['activate'] = true;
  }
  public collapseWidgetTouch(idx: any) {
    this.blocks[idx]['collapse'] = !this.blocks[idx]['collapse'];
  }
  public collapseWidget(e, idx: any) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.blocks[idx]['collapse'] = false;
  }
  public unCollapseWidget(e, idx: any) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.blocks[idx]['collapse'] = true;
  }
  public collapseAllWidget() {
    this.collapseOnload = true;
    this.blocks.map((itm) => {
      itm['collapse'] = true;
    })
  }
  public unCollapseAllWidget() {
    this.collapseOnload = false;
    this.blocks.map((itm) => {
      itm['collapse'] = false;
    })
  }
  public isValid() {
    let hasStartWrapper = false;
    let startWrapper = false;
    let prevWidget = undefined;
    var currentBlock = this.blocks[this.blocks.length - 1];
    var lastBlock = currentBlock;
    if (lastBlock) {
      var lastType = lastBlock["type"];
      for (let itm of this.blocks) {
        let currentWidget = itm.type;

        if (currentWidget == 'startwrapper') {
          hasStartWrapper = true;
          startWrapper = true;
        }
        if (currentWidget == 'linkdescription' && startWrapper) {
          return 'The link description widget cannot be used inside of a Form Wrapper';
        }
        if (itm.isForm === "true" && !startWrapper) {
          return 'One or more of the widgets you are using are form widgets that require user answers. Please add the form widgets inside a wrapper to continue.'
        }
        if (currentWidget == 'endwrapper') {
          if (prevWidget == 'startwrapper') {
            return 'Please add one or more form widgets inside the wrapper';
          }
          if (startWrapper) {
            startWrapper = false;
          } else {
            return 'An End Wrapper has no prior start wrapper';
          }
        }
        prevWidget = currentWidget;
      }
      if (lastType !== 'endwrapper' && startWrapper) {
        return 'A Start Wrapper has no corresponding End Wrapper';
      }
    }
    if (this.enableFormWraps && hasStartWrapper === false) {
      return 'Please add Start and End Wrappers to enclose other widgets';
    }
    return undefined;
  }
  public save() {
    let returnMessage = this.isValid();
    if (!returnMessage) {
      this.tileSave("", true, false, false, this.selectedData);
    } else {
      this.layoutUtilsService.showNotification(this.translate.instant(returnMessage), this.translate.instant('Dismiss'));
    }
  }
  public Newpreview() {
    const dialogRef = this.dialog.open(ModalTileDialogComponent, {
      disableClose: false,
      width: '100vw',
      data: {
        title: '',
        data: { _id: this.selectedData._id },
        readonly: false,
        isSuperAdmin: this.isSuperAdmin,
        isAdmin: this.isAdmin
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // nothing to do
      }
    });
  }
  public preview() {
    const dialogRef = this.dialog.open(ModalPreviewDialogComponent, {
      disableClose: false,
      panelClass: 'myPreviewClass',
      data: {
        title: this.translate.instant('Preview'),
        data: this.formRequestService.getTileLink(this.selectedData._id, undefined, true)
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // nothing to do
      }
    });
  }
  // public background() {
  //   const dialogRef = this.dialog.open(ModalBackgroundDialogComponent, {
  //       disableClose: false,
  //       panelClass: 'myBackroundClass',
  //       data: {
  //         title: 'TILE BACKGROUNDS',
  //         data: this.selectedData,
  //       }
  //     });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       // nothing to do
  //       this.selectedData.tileBackgroundPortrait = result.tileBackgroundPortrait;
  //       this.selectedData.tileBackgroundLandscape = result.tileBackgroundLandscape;
  //     }
  //   });
  // }
  public icon() {
    // const dialogRef = this.dialog.open(ModalGalleryDialogComponent, {
    //   width: '1600px',
    //   disableClose: false,
    //   panelClass: 'myBackroundClass',
    //   autoFocus: false,
    //   data: {
    //     title: this.translate.instant('Image Library'),
    //     data: this.selectedData,
    //   }
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   if (result) {
    //     this.selectedData.art = result;
    //   }
    // });
  }
  public checkStartWrapper(type: string) {
    if (type === "startwrapper") {
      this.startWrapper = true;
    } else if (type === "endwrapper") {
      if (this.startWrapper) {
        this.startWrapper = false;
      }
    }

    return true;
  };
  newTile(message: any, yesBtn: any, noBtn: any, cb: any) {
    this.utils.iAlertConfirm("confirm", "Confirm", message, yesBtn, noBtn, (res) => {
      cb(res["value"]);
    });
  };
  languageChange(langCode: string, saved: boolean = false) {
    if (!saved) {
      let isNew: boolean = this.selectedData.hasOwnProperty("_id") ? false : true;
      if (isNew) {
        this.newTile('You must save the changes to update in other languages', 'Save', 'Discard', (value) => {
          if (value === 'Save') {
            this.tileSave("", false, false, false, {}, true, langCode);
          } else {
            this.selectedLanguage = undefined;
            setTimeout(() => {
              this.selectedLanguage = 'en';
            }, 300);
          }
        });
      } else {
        this.langReset(langCode);
      }
    } else {
      this.loaderService.display(false);
      this.langReset(langCode);
    }
  };

  langReset(langCode: string, isSpinner?: boolean) {
    this.selectedLanguage = langCode;

    // Reset the tile
    if (this.selectedData.hasOwnProperty(langCode)) {
      this.selectedData['title'] = this.selectedData[langCode]['title'];
      this.selectedData['notes'] = this.selectedData[langCode]['notes'];
      this.selectedData['rtl'] = this.selectedData[langCode]['rtl'];
    } else {
      this.selectedData['title'] = '';
      this.selectedData['notes'] = '';
      this.selectedData['rtl'] = false;
      this.selectedData[langCode] = { title: '', notes: '', rtl: false };
    }

    this.blocks = [];
    if (this.selectedData.blocksData.length > 0) {
      for (let i = 0; i < this.selectedData.blocksData.length; i++) {
        let currentBlock = this.selectedData.blocksData[i];
        let type = this.selectedData.blocksData[i].hasOwnProperty("type") ? this.selectedData.blocksData[i].type : "";
        this.loadWidgets("", type, currentBlock, "");
      }
    }
    if (isSpinner) {
      this.loaderService.display(true);
    }
  };
  widgetAdd(idx) {
    if (this.selectedIndex === idx) {
      this.selectedIndex = undefined;
    } else {
      this.selectedIndex = idx;
    }
  }
  public loadWidgets(e, type, blockData, blkName, collapseOnload = false, singleAdd = false) {
    if (!this.utils.isNullOrEmpty(e)) {
      e.stopImmediatePropagation();
      e.preventDefault();
    }
    if (singleAdd && this.blocks.length > 0) {
      this.analyzeWidgets(this.blocks);
    }
    let viewName = '';
    var result = !this.utils.isNullOrEmpty(blkName) && this.blocks.length > 0 ? this.manageWidgets(type, blkName) : this.checkStartWrapper(type);

    if (result) {
      let newBlock = undefined;
      if (type === "text") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "textView";
      }

      if (type === "linkdescription") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "linkDescriptionView";
      }

      if (type === "video") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "videoView";
      }

      if (type === "picture") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "pictureView";
      }

      if (type === "disqus") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "disqusView";
      }

      if (type === "feed") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "feedView";
      }

      if (type === "calendar") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "calendarView";
      }

      if (type === "share") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "shareView";
      }

      if (type === "patients") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "patientsView";
      }

      if (type === "inquiry") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "inquiryView";
      }

      if (type === "survey") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], this.widgetCategories, this.utils, this.selectedOrganization, collapseOnload);
        viewName = "surveyView";
      }

      if (type === "questionnaire") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], this.widgetCategories, this.utils, this.selectedOrganization, collapseOnload);
        viewName = "questionnaireView";
      }

      if (type === "startwrapper") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "startWrapperView";
      }

      if (type === "title") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "formTitleView";
      }

      if (type === "questions") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], this.widgetCategories, this.utils, this.selectedOrganization, collapseOnload);
        viewName = "questionsView";
      }

      if (type === "attendance") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "attendanceView";
      }

      if (type === "confirmation") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "confirmationView";
      }

      if (type === "password") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "passwordView";
      }

      if (type === "next") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);

        viewName = "nextView";
      }

      if (type === "formphoto") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "formPhotoView";
      }

      if (type === "formdocument") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "formDocumentView";
      }

      if (type === "painlevel") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "painLevelView";
      }

      if (type === "drawtool") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "drawToolView";
      }

      if (type === "physician") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "physicianView";
      }

      if (type === "endwrapper") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "endWrapperView";
      }

      if (type === "fill") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "fillView";
      }

      if (type === "notes") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "notesView";
      }

      if (type === "buttons") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "buttonsView";
      }

      if (type === "contactus") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "contactusView";
      }

      if (type === "placefull") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);

        viewName = "placefullView";
      }

      if (type === "addtocart") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "addToCartView";
      }

      if (type === "cart") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "cartView";
      }

      if (type === "blanksform") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "blanksFormView";
      }

      if (type === "exclusiveurl") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "exclusiveUrlView";
      }

      if (type === "fileupload") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "fileUploadView";
      }

      if (type === "pushpay") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "pushPayView";
      }

      if (type === "threedcart") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "threedCartView";
      }

      if (type === "blogs") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "blogsView";
      }

      if (type === "chat") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "chatView";
      }

      if (type === "account") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, this.profileDatas, [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "accountView";
      }

      if (type === "profile") {
        newBlock = new BlockOrganizer(blockData, type, this.selectedLanguage, this.profileDatas, [], this.utils, this.selectedOrganization, collapseOnload);
        viewName = "profileView";
      }
      if (newBlock) {
        if (this.selectedIndex !== undefined) {
          this.blocks.splice(this.selectedIndex, 0, newBlock);
          // this.selectedIndex++;
        } else {
          this.blocks.push(newBlock);
        }
        this.selectedIndex = undefined;
      }
      // console.log('loadWidgets', this.blocks);
    }
  }
  public moveSubData(e, isUp, idx) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    let blocks = JSON.parse(JSON.stringify(this.blocks));
    let newIdx = idx;
    let currentItem = blocks.splice(idx, 1);
    if (isUp) {
      newIdx = newIdx - 1;
    } else {
      newIdx = newIdx + 1;
    }
    blocks.splice(newIdx, 0, currentItem[0]);
    this.blocks = JSON.parse(JSON.stringify(blocks));
  }
  public actionData(idx, dataObject) {
    // console.log('clickWidget', idx, dataObject);
    if (dataObject.action === 'imageLibrary') {
      // this.galleryLibrary(idx, dataObject);
    } else if (dataObject.action === 'videoLibrary') {
      // this.videoLibrary(idx, dataObject);
    } else if (dataObject.action === 'videoThumb') {
      // add thumb upload
    } else {
      //console.log('block change', this.blocks[idx]);
    }
    // we ,ight need to reset the block this.blocks[idx]=dataObject.block
  }

  public galleryLibrary(idx, dataObject) {
    // const dialogRef = this.dialog.open(ModalGalleryDialogComponent, {
    //   width: '1600px',
    //   disableClose: false,
    //   autoFocus: false,
    //   data: {
    //     title: this.translate.instant('Upload image'),
    //     data: [],
    //   }
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   if (result) {
    //     let blocks = JSON.parse(JSON.stringify(this.blocks));
    //     blocks[idx]['data'][dataObject['block']['target']] = result;
    //     this.blocks = JSON.parse(JSON.stringify(blocks));
    //   }
    // });
  }
  // public videoLibrary(idx, dataObject) {
  //   const dialogRef = this.dialog.open(ModalVideosDialogComponent, {
  //       width: '1600px',
  //       disableClose: false,
  //       panelClass: 'myBackroundClass',
  //       data: {
  //         title: 'Video Library',
  //         data: '',
  //       }
  //     });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       if(result.hasOwnProperty('vimeoId')){
  //         let blocks = JSON.parse(JSON.stringify(this.blocks));
  //         blocks[idx]['data'][dataObject['block']['target']] = environment.vimeoUrl + result.vimeoId;
  //         this.blocks = JSON.parse(JSON.stringify(blocks));
  //       }
  //     }
  //   });
  // }
  /*   Get Organization profile datas    */
  getOrgProfileDatas() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.formRequestService.getDataLByOrg('tileblock/getprofile', (data, error) => {
        this.loading = false;
        if (error) {
          //console.log(error);
        }
        if (data) {
          this.profileDatas = data.results;
        }
      }, true);
    }
  };
  // getOrganizations() {
  //   this.formRequestService.getDataL('organization', (data, error) => {
  //     if (error) {
  //       //console.log(error);
  //     }
  //     if (data) {
  //       this.organizations = data.results;
  //     }
  //   }, true);
  // };

  getWhiteTheme() {
    this.defaultThemeId = "-1";

    if (this.utils.isArray(this.tileThemes) && this.tileThemes.length > 0) {
      for (let i = 0; i < this.tileThemes.length; i++) {
        var themeObj = this.tileThemes[i];

        if (themeObj.hasOwnProperty("name") && !this.utils.isNullOrEmpty(themeObj["name"]) && this.utils.trim(themeObj["name"].toLowerCase()) === "white") {
          this.defaultThemeId = themeObj["_id"];
          break;
        }
      }
    }

    this.template = this.defaultThemeId;
  };
  private buildSetting() {
    let fields: any[] = this.fieldsObject;
    this.formRequestService.getMetaData('tile', fields, (data, error) => {
      if (error) {
        this.layoutUtilsService.showNotification('Error: ' + error, 'Dismiss');
      }
      if (data) {
        this.originalTableSetting = Object.assign({}, data.results);
        let newFields = data.results.fields.filter((i) => i.name === 'flag');
        if (newFields.length > 0) {
          this.flagMeta = newFields[0];
        }
        this.setWidgetRights();
      }
    });
  }
  setWidgetRights() {
    this.formRequestService.getSingleDataNoGet('organization/package', this.selectedOrganization, (data, error) => {
      if (error) {
        //console.log(error);
      }
      if (data) {
        if (data && data.length > 0) {
          var packageRights = data[0].hasOwnProperty('rights') && data[0].rights.length > 0 ? data[0].rights : [];

          if (this.utils.isArray(packageRights) && packageRights.length > 0) {
            for (let i = 0; i < packageRights.length; i++) {
              this.widgetRights.push(packageRights[i].toLowerCase().replace(/ /g, ''))
            }
          }
        }
      }
    });
  };
  analyzeWidgets(blocks) {
    this.startWrapper = false;
    let idx = 0;
    for (let itm of blocks) {
      if (this.selectedIndex === idx) {
        break;
      }
      let currentWidget = itm.type;

      if (currentWidget == 'startwrapper') {
        this.startWrapper = true;
      } else if (currentWidget == 'endwrapper') {
        this.startWrapper = false;
      }
      idx++;
    }
  }
  manageWidgets(currentWidget: string, widgetName: string) {
    // if (this.widgetRights.length > 0) {
    //   var widgetBlockName = widgetName.toLowerCase().replace(/\s+/g, '');
    //   var rightIndex = this.widgetRights.indexOf(widgetBlockName);

    //   if (rightIndex == -1) {
    //     var orgIndex = this.organizations.map(b => {
    //       return b['_id'];
    //     }).indexOf(this.selectedOrganization);

    //     var orgName = orgIndex !== -1 ? this.organizations[orgIndex]["name"] : "";
    //     this.layoutUtilsService.showNotification(this.translate.instant(orgName + ' does not have access to this widget'), this.translate.instant('Dismiss'));
    //     return false;
    //   }
    // }

    var formGroupCheck = 0;
    var formOneCheck = 0;
    var exclusiveCheck = 0;
    var displayCheck = 0;
    var forms = ['title', 'account', 'attendance', 'survey', 'questionnaire', 'questions', 'confirmation', 'inquiry', 'profile', 'password', 'next', 'user', 'formphoto', 'mobilevideo', 'physician'];
    var formsOne = ['fill', 'notes', 'buttons', 'contactus', 'placefull', 'addtocart', 'cart', 'blanksform'];
    var displayWigets = ['text', 'linkdescription', 'video', 'image', 'url', 'picture', 'disqus', 'feed', 'calendar'];
    var exclusiveWidgets = ['exclusiveurl', 'fileupload', 'pushpay', 'threedcart', 'blogs', 'chat'];
    var formIndex = forms.indexOf(currentWidget);
    var formsOneIndex = formsOne.indexOf(currentWidget);
    var displayIndex = displayWigets.indexOf(currentWidget);
    var exclusiveIndex = exclusiveWidgets.indexOf(currentWidget);
    var blogsChk = false;

    if (currentWidget == 'startwrapper') {
      if (!this.startWrapper) {
        this.startWrapper = true

        return true;
      } else {
        this.layoutUtilsService.showNotification(this.translate.instant("Start wrapper already added"), this.translate.instant('Dismiss'));
      }

      return false;
    }
    let currentBlockIdx = this.blocks.length - 1;
    if (this.selectedIndex != undefined) {
      currentBlockIdx = this.selectedIndex - 1;
      if (currentBlockIdx < 0) {
        currentBlockIdx = 0;
      }
    }
    var currentBlock = this.blocks[currentBlockIdx];
    var lastBlock = currentBlock;
    var lastType = lastBlock["type"];

    if (!this.startWrapper && currentWidget === "confirmation") {
      if (lastType === "survey" || lastType === "questionnaire") {
        var isSingle = false;

        if (lastType === "survey") {
          isSingle = lastBlock["data"]["multiple"] === "false" ? true : false;
        }

        if (lastType === "questionnaire") {
          isSingle = lastBlock["data"]["questionType"] === "single" ? true : false;
        }

        if (isSingle) {
          if (lastType === "survey") {
            for (let i = 0; i < lastBlock["data"]["questions"].length; i++) {
              lastBlock["data"]["confirmation"].push("");
            }
          }

          if (lastType === "questionnaire") {
            for (let i = 0; i < lastBlock["data"]["options"].length; i++) {
              lastBlock["data"]["confirmation"].push("");
            }
          }
        }

        return false;
      }
    }

    if (currentWidget == "endwrapper" && lastType == "startwrapper") {
      this.layoutUtilsService.showNotification(this.translate.instant("Please add one or more form widgets inside the wrapper"), this.translate.instant('Dismiss'));

      return false;
    }

    if (currentWidget == "confirmation" && lastType == "next") {
      this.layoutUtilsService.showNotification(this.translate.instant("Next Tile already added in this group!!!"), this.translate.instant('Dismiss'));
      return false;
    }

    if (currentWidget == "next" && lastType == "confirmation") {
      this.layoutUtilsService.showNotification(this.translate.instant("Confirmation already added in this group!!!"), this.translate.instant('Dismiss'));
      return false;
    }

    var linkForm = this.blocks.filter(b => {
      return b["type"] === "title";
    });

    if (currentWidget === "next" && this.startWrapper && linkForm.length > 0) {
      var linkBlock = linkForm[0];

      if (linkBlock[0]["data"]["title"]) {
        this.layoutUtilsService.showNotification(this.translate.instant("Next Widget cannot combine with linkable forms!!!"), this.translate.instant('Dismiss'));
        return false;
      }
    }

    if (currentWidget == 'endwrapper') {
      if (this.startWrapper) {
        this.startWrapper = false

        return true;
      } else {
        this.layoutUtilsService.showNotification(this.translate.instant("No Start wrapper added!! Click start to end wrapper !!"), this.translate.instant('Dismiss'));
      }

      return false;
    }

    if (lastType === "next" && currentWidget == 'next') {
      this.layoutUtilsService.showNotification(this.translate.instant("You cannot add Next widget after Next widget"), this.translate.instant('Dismiss'));
      return false;
    }

    if (lastType === "next" && lastBlock["data"]["type"] === "tile") {
      this.layoutUtilsService.showNotification(this.translate.instant("You cannot add widget after Next widget"), this.translate.instant('Dismiss'));
      return false;
    }

    if (this.startWrapper && (currentWidget === "title" || currentWidget === "confirmation" || currentWidget === "next")) {
      var grpIdx = -1;
      var confirmIndex = -1;
      var nextIndex = -1;
      var nextWidgetIndex = -1;
      var titleIndex = -1;

      for (let i = this.blocks.length - 1; 0 <= i; i--) {
        if (this.blocks[i]["type"] === "title") {
          titleIndex = i;
        }

        if (this.blocks[i]["type"] === "confirmation") {
          confirmIndex = i;
        }

        if (this.blocks[i]["type"] === "next") {
          nextIndex = i;
        }

        if (this.blocks[i]["type"] === "startwrapper") {
          grpIdx = i;
          break;
        }
      }

      nextWidgetIndex = this.blocks.length - 1 > grpIdx ? grpIdx + 1 : -1;

      if (currentWidget == "next" && nextIndex !== -1) {
        this.layoutUtilsService.showNotification(this.translate.instant("Next Tile already added in this group. Please end wrapper to close this group"), this.translate.instant('Dismiss'));
        return false;
      }

      if (currentWidget == "confirmation" && nextIndex !== -1) {
        this.layoutUtilsService.showNotification(this.translate.instant("Confirmation already added in this group. Please end wrapper to close this group"), this.translate.instant('Dismiss'));
        return false;
      }

      if (lastType === "confirmation" || lastType === "next") {
        this.layoutUtilsService.showNotification(this.translate.instant("Please end wrapper to close this group for adding this widget"), this.translate.instant('Dismiss'));

        return false;
      }

      if (nextWidgetIndex !== -1 && (currentWidget != "confirmation" && currentWidget != "next") && lastType != "startwrapper") {
        var nextType = this.blocks[nextWidgetIndex]["type"];

        if (nextType == "title" && titleIndex !== -1) {
          this.layoutUtilsService.showNotification(this.translate.instant("One title is allowed inside the group"), this.translate.instant('Dismiss'));
        } else {
          this.layoutUtilsService.showNotification(this.translate.instant("Title will be in the top inside the group"), this.translate.instant('Dismiss'));
        }

        return false;
      }
    }

    if (formsOneIndex !== -1 && this.startWrapper) {
      this.layoutUtilsService.showNotification(this.translate.instant("A restricted widget cannot be combined with a form widget"), this.translate.instant('Dismiss'));
      return false;
    }

    if ((displayIndex !== -1 && currentWidget !== 'text') && this.startWrapper) {
      this.layoutUtilsService.showNotification(this.translate.instant("This widget cannot be used inside of a Form Wrapper"), this.translate.instant('Dismiss'));
      return false;
    }

    for (let i = 0; i < forms.length; i++) {
      var formIndx = this.blocks.map(b => {
        return b['type'];
      }).indexOf(forms[i]);

      if (formIndx !== -1) {
        formGroupCheck++;
      }
    }

    for (let i = 0; i < formsOne.length; i++) {
      var formOneIndx = this.blocks.map(b => {
        return b['type'];
      }).indexOf(formsOne[i]);

      if (formOneIndx !== -1) {
        formOneCheck++;
      }
    }

    for (let i = 0; i < exclusiveWidgets.length; i++) {
      var exclusiveWidgetsIndx = this.blocks.map(b => {
        return b['type'];
      }).indexOf(exclusiveWidgets[i]);

      if (exclusiveWidgetsIndx !== -1) {
        exclusiveCheck++;
      }
    }

    for (let i = 0; i < displayWigets.length; i++) {
      var displayWigetsIndx = this.blocks.map(b => {
        return b['type'];
      }).indexOf(displayWigets[i]);

      if (displayWigetsIndx !== -1) {
        displayCheck++;

        if (displayWigets[i] === "blogs") {
          blogsChk = true;
        }
      }
    }

    if ((formGroupCheck > 0 && formsOneIndex !== -1) || (formsOneIndex !== -1 && formOneCheck > 0) || (formOneCheck > 0 && formIndex !== -1)) {
      if ((formGroupCheck > 0 && formsOneIndex !== -1)) {
        this.layoutUtilsService.showNotification(this.translate.instant("A Restricted widget cannot be combined with a Form widget"), this.translate.instant('Dismiss'));
        return false;
      }

      if (formOneCheck > 0 && formIndex !== -1) {
        this.layoutUtilsService.showNotification(this.translate.instant("A Form widget cannot be added in the same Tile with Restricted widgets"), this.translate.instant('Dismiss'));
        return false;
      }
    }

    if (exclusiveIndex !== -1 || exclusiveCheck > 0) {
      if ((formGroupCheck > 0 || formOneCheck > 0 || displayCheck > 0) || (formIndex !== -1 || formsOneIndex !== -1 || displayIndex !== -1)) {
        this.layoutUtilsService.showNotification(this.translate.instant("An exclusive widget cannot be combined with other widgets"), this.translate.instant('Dismiss'));
        return false;
      }

      if (exclusiveCheck > 0) {
        this.layoutUtilsService.showNotification(this.translate.instant("An exclusive widget cannot be added with same exclusive widgets"), this.translate.instant('Dismiss'));
        return false;
      }
    }

    if (blogsChk && currentWidget === "blogs") {
      this.layoutUtilsService.showNotification(this.translate.instant("Blog already exists"), this.translate.instant('Dismiss'));
      return false;
    }

    if (this.startWrapper && currentWidget === 'profile') {
      var startIdx = -1;

      for (let i = this.blocks.length - 1; 0 <= i; i--) {
        if (this.blocks[i]["type"] === "startwrapper") {
          startIdx = i;
          break;
        }
      }

      if (startIdx !== -1) {
        var clearEnabled = this.blocks[startIdx]["data"]["refresh"];

        if (clearEnabled) {
          this.layoutUtilsService.showNotification(this.translate.instant("Unable to add profile widget with clear fields"), this.translate.instant('Dismiss'));
          return false;
        }
      }
    }

    return true;
  };

  startEndWrapperCheck() {
    let currentBlocks: any = this.blocks;
    let wrapper: any = {
      "startWrapper": 0,
      "endWrapper": 0,
      "passed": 0,
      "failed": 0
    }

    for (let i = 0; i < currentBlocks.length; i++) {
      if (currentBlocks[i].hasOwnProperty("block") && currentBlocks[i].hasOwnProperty("type")) {
        var type = currentBlocks[i]["type"];

        if (type === "startwrapper") {
          wrapper["startWrapper"] = wrapper["startWrapper"] + 1;
        } else if (type === "endwrapper") {
          wrapper["endWrapper"] = wrapper["endWrapper"] + 1;
        }

        if (wrapper["startWrapper"] === 1 && wrapper["endWrapper"] == 1 && type === "endwrapper") {
          wrapper["startWrapper"] = 0;
          wrapper["endWrapper"] = 0;

          wrapper["passed"] = wrapper["passed"] + 1;
        }


        if ((wrapper["startWrapper"] > 1 || wrapper["endWrapper"] > 1) || (wrapper["startWrapper"] === 1 && wrapper["endWrapper"] == 1 && type === "startWrapper") || (i === currentBlocks.length - 1 && (wrapper["startWrapper"] === 1 || wrapper["endWrapper"] === 1))) {
          wrapper["failed"] = 1;
          break;
        }
      }
    }


    return wrapper["failed"] === 1 ? false : true;
  };
  assignExistsLanguage(langCode?: string) {
    if (langCode) {
      let currLang: string = this.selectedLanguage;
      this.selectedLanguage = langCode;

      setTimeout(() => {
        this.selectedLanguage = currLang;
      }, 500);
    }
  };
  tileSave(e: any, isUpdate?: boolean, isDuplicate?: boolean, updated?: boolean, newTileObj?: Object, langSave?: boolean, langCode?: string, selectData?: boolean, dataToSelect?: string, isSelectNew: boolean = false) {
    if (!this.utils.isNullOrEmpty(e)) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.loaderService.display(true);
    let updateId: string = "-1";
    let tileObj: any = { ...this.selectedData };

    if (!isUpdate && isDuplicate) {
      this.selectDBItem(this.selectedOriginalData);
      tileObj = Object.assign({}, this.selectedData);
      if (updated) {
        updateId = tileObj["_id"];
      }

      tileObj["title"] = "Copy of " + tileObj["title"];
      delete tileObj["_id"];
    }

    if (this.utils.isNullOrEmpty(tileObj["title"])) {
      this.title.nativeElement.focus();
      this.layoutUtilsService.showNotification(this.translate.instant("You must at least enter a Tile title"), this.translate.instant('Dismiss'));
      // this.assignExistsLanguage(langCode);
      this.loaderService.display(false);
      return false;
    }

    // if (tileObj["category"] === "-1") {
    //   this.utils.iAlert('error', 'Information', 'Please select a category for the Tile');
    //   this.assignExistsLanguage(langCode);
    //   this.loaderService.display(false);
    //   return false;
    // }

    // this.selectedData.blocksData = this.getBlocks(); // we are taking what is save and putting it here

    let isNewBlock: boolean = tileObj.hasOwnProperty("_id") ? false : true;
    let listOfnew = this.getBlocks(isNewBlock);
    tileObj.blocksData = listOfnew;
    tileObj.updated = updated;
    this.checkTileOrgs(tileObj, (currTileObj: any, deleteId: any) => {
      if (this.selectedLanguage !== "en") {
        currTileObj[this.selectedLanguage] = {};
        currTileObj[this.selectedLanguage]["title"] = this.selectedData.title;
        currTileObj[this.selectedLanguage]["notes"] = this.selectedData.notes;
        currTileObj[this.selectedLanguage]["rtl"] = this.selectedData.rtl;

        currTileObj["title"] = this.selectedOriginalData.title;
        currTileObj["notes"] = this.selectedOriginalData.notes;
        currTileObj["rtl"] = this.selectedOriginalData.rtl;
      }
      currTileObj["category"] = { "_id": currTileObj["category"] };
      if (tileObj.updated) {
        if (typeof (currTileObj["organizationId"]) == 'string')
          currTileObj["organizationId"] = [currTileObj["organizationId"]];
      }
      this.selectedData.category = currTileObj['category']['_id'];
      this.saveTile(currTileObj, deleteId, isUpdate, isDuplicate, updateId, newTileObj, langSave, langCode, selectData, dataToSelect, isSelectNew);
    });
  };
  getBlocks(isNewBlock) {
    let currentBlocks: any = this.blocks;
    let blckObj: any = new GetBlocks(currentBlocks, this.selectedLanguage, isNewBlock, this.utils);
    let blkDataObjs: any = blckObj.getBlockDatas();
    if (blkDataObjs["blocks"].length > 0) {
      return blkDataObjs["blocks"];
    } else {
      return [];
    }
  };
  checkTileOrgs(tile: any, cb: any) {
    let deleteId: string = "-1";

    if (tile.hasOwnProperty("_id")) {
      let id: any = tile["_id"];
      let orgs: any = tile["organizationId"];
      let createdOrg: any = tile["createdOrg"];

      if (orgs.length > 0) {
        this.organizationCheck(createdOrg, orgs, (result1: any, result2: any) => {
          if ((result1 === 0 && result2 === 0) || (result1 === 2 && result2 === 2)) {
            // "Save as new copy"
            let obj2: any = !this.utils.isEmptyObject(this.selectedData) ? Object.assign({}, this.selectedData) : {};
            let title: string = this.selectedData.title;

            if (title === obj2["title"]) {
              this.layoutUtilsService.showNotification(this.translate.instant("Please Modify the Tile name"), this.translate.instant('Dismiss'));
              return false;
            }

            tile["createdOrg"] = this.selectedOrganization;
            tile["organizationId"] = [this.selectedOrganization];
            delete tile["_id"];

          } else if (result1 === 1 && result2 === 0) {
            // "Save and delete original"
            let tileId: string = tile["_id"];
            deleteId = tileId;
            let currOrgs: any = tile["organizationId"];
            let orgIdx: number = currOrgs.indexOf(this.selectedOrganization);
            currOrgs.splice(orgIdx, 1);
            let updateData: any = { "organizationId": currOrgs };
            if (!this.loading) {
              this.loading = true;
              this.errorMessage = '';
              this.formRequestService.saveData(this.dataType, updateData, (data, error) => {
                if (error) {
                  this.errorMessage = error;
                  this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, 'Dismiss');
                }
                if (data) {
                  this.layoutUtilsService.showNotification(this.dataTypeDisplay + ' Updated', 'Dismiss');
                }
                this.loading = false;
              });
            }
            tile["createdOrg"] = this.selectedOrganization;
            tile["organizationId"] = [this.selectedOrganization];
            delete tile["_id"];
          }

          cb(tile, deleteId);
        });
      }
    } else {
      cb(tile, deleteId);
    }
  };
  assignIndexes(blks: any[]) {
    for (let i = 0; i < blks.length; i++) {
      blks[i]["index"] = i;
    }

    return blks;
  };
  arrangeBlocks(currBlks: any[]) {
    let arrangedBlocks: any[] = this.utils.sortArray(currBlks, true, "index");

    for (let i = 0; i < arrangedBlocks.length; i++) {
      delete arrangedBlocks[i]["index"];
    }

    return arrangedBlocks;
  };
  saveTile(tileObj: Object, deleteId?: string, isUpdate?: boolean, isDuplicate?: boolean, updatedId?: string, newTileObj?: Object, langSave?: boolean, langCode?: string, selectData?: boolean, dataToSelect?: string, isSelectNew: boolean = false) {
    // return;
    if (!this.utils.isEmptyObject(tileObj)) {
      if (!this.loading) {
        let isNew: boolean = tileObj.hasOwnProperty("_id") ? false : true;
        this.loading = true;
        this.errorMessage = '';
        this.currentSelectedBlocks = JSON.parse(JSON.stringify(this.blocks));
        this.formRequestService.saveDataNoPerfix(this.dataType, tileObj, (data, error) => {
          if (error) {
            this.errorMessage = error;
            this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, 'Dismiss');
            this.loaderService.display(false);
            this.loading = false;
          }
          if (data) {
            let resTile = data.results;
            let savedBlocks = resTile.blocksData;
            this.loading = false;
            if (!this.utils.isEmptyObject(resTile) && resTile.hasOwnProperty("_id") && !this.utils.isNullOrEmpty(resTile["_id"])) {
              // if (!isUpdate && !isDuplicate && !langSave && !selectData) {
              //   let tileMessage: string = tileObj.hasOwnProperty("_id") ? "updated" : "saved";
              //
              //   this.loaderService.display(false);
              //   this.tile_list.loadData();
              //   this.utils.iAlert('success', '', 'Tile ' + tileMessage + ' successfully');
              //   this.assignBlockData(savedBlocks);
              // } else
              if (!isUpdate && isDuplicate) {
                this.utils.iAlert('success', '', this.translate.instant('Tile') + ' ' + this.translate.instant('duplicated successfully'));
                this.setTileContent(resTile, isNew, true, true, true);
              } else if (isUpdate && isDuplicate && !selectData) {
                this.setTileContent(resTile, isNew, true, false, false, false, true);
                this.tileSave("", false, true, true);
              } else if (isUpdate && !isDuplicate && selectData) {
                this.loaderService.display(false);
                this.selectItem(dataToSelect);
              } else if (isUpdate && !isDuplicate && !selectData) {
                //come here
                let tileMessage: string = tileObj.hasOwnProperty("_id") ? "updated" : "saved";
                this.utils.iAlert('success', '', this.translate.instant('Tile') + ' ' + tileMessage + ' successfully');
                if (this.isDialog) {
                  this.onSaveReturn.emit({ _id: resTile._id, name: resTile.title, active: false });
                  this.loaderService.display(false);
                } else {
                  if (isSelectNew) {
                    this.loaderService.display(false);
                    this.widgetTileReset(true, true);
                  } else {
                    this.setTileContent(resTile, isNew, true, true, true);
                  }
                }
              } else if (langSave && !selectData) {
                let tileMessage: string = tileObj.hasOwnProperty("_id") ? "updated" : "saved";
                this.utils.iAlert('success', '', this.translate.instant('Tile') + ' ' + tileMessage + ' successfully');
                this.setTileContent(resTile, isNew, true, false, true);
                this.languageChange(langCode, true);
                // this.assignBlockData(savedBlocks);
              }
            }

            // if(!isNew){
            // 	this.layoutUtilsService.showNotification(this.dataTypeDisplay + ' Updated', 'Dismiss');
            // }else{
            //   this.selectedData['_id'] = data.results._id; // change to the whole object
            // 	this.layoutUtilsService.showNotification(this.dataTypeDisplay + ' Created', 'Dismiss');
            // }
          }
        });
      } else {
        this.loaderService.display(false);
      }
    } else {
      this.loaderService.display(false);
    }

  };
  organizationCheck(createdOrg: string, orgs: any[], cb) {
    var orgsMatchChk = orgs.indexOf(this.selectedOrganization);
    if (orgsMatchChk === -1) {
      var html = '';
      var orgNames = this.getOrganizationName(orgs).split(',');
      html += "<ul style='text-align: left;'>";

      for (let i = 0; i < orgNames.length; i++) {
        html += '<li>' + orgNames[i] + '</li>';
      }

      html += "</ul>";

      this.utils.iQuestions("question", this.translate.instant('Warning'), "This tile was assigned to the organizations : " + html + ".<br>If you choose to modify it, it will create a new copy in this organization.", "Save as new copy", "Close", "", (r) => {
        if (r["value"] === "Save as new copy") {
          cb(0, 0);
        }
      });
    } else if (typeof orgs !== 'string' && orgs[0] !== this.selectedOrganization) {
      this.utils.iQuestions("question", this.translate.instant('Warning'), "This tile was linked originally to <b>" + this.getOrganizationName(orgs[0].split(',')) + "</b>.<br>If you choose to modify it, it will create a new copy.<br>If the original tile is used in one of your Events or Pages, it will be removed. You may use the newly created copy in the Event or page.", "Save and delete original", "Close", "", (r) => {
        if (r["value"] === "Save and delete original") {
          cb(1, 0);
        }
      });
    } else if (typeof orgs !== 'string' && orgs.length > 1 && orgs[0] === this.selectedOrganization) {
      var html = '';
      var orgNames = this.getOrganizationName(orgs).split(',');
      html += "<ul style='text-align: left;'>";

      for (let i = 0; i < orgNames.length; i++) {
        html += '<li>' + orgNames[i] + '</li>';
      }

      html += "</ul>";

      this.utils.iQuestions("question", this.translate.instant('Warning'), "Saving this Tile will modify the content in the following organizations:" + html, "Continue", "Close", "Save as a copy", (r) => {
        if (r["value"] === "Continue") {
          cb(2, 0);
        } else if (r["value"] === "Save as a copy") {
          cb(2, 2);
        }
      });
    } else {

      cb(2, 1);
    }
  };
  getOrganizationName(orgs: string[], currentOrg?: string) {
    let orgNames: any[] = [];

    for (let i = 0; i < this.organizations.length; i++) {
      let currOrg: any = this.organizations[i];
      let orgId: string = currOrg["_id"];
      let orgIdx: number = orgs.indexOf(orgId);

      if (orgIdx !== -1 && (this.utils.isNullOrEmpty(currentOrg) || (currOrg._id !== currentOrg))) {
        orgNames.push(currOrg["name"]);
      }
    }

    return orgNames.join(",");
  };
  assignBlockData(savedBlocks: any[]) {
    let currentBlocks: any[] = this.blocks;
    this.blocks = [];
    for (let i = 0; i < savedBlocks.length; i++) {
      var currSavedBlock = savedBlocks[i];

      if (!this.utils.isEmptyObject(currentBlocks[i]) && currentBlocks[i].hasOwnProperty("existingData")) {
        currentBlocks[i]["existingData"] = currSavedBlock;
        this.blocks.push(currSavedBlock);
      }
    }
  };
  cleanBlockData(savedBlocks: any[]) {
    let currentBlocks: any[] = this.blocks;
    this.blocks = [];
    for (let i = 0; i < savedBlocks.length; i++) {
      var currSavedBlock = savedBlocks[i];

      if (!this.utils.isEmptyObject(currentBlocks[i]) && currentBlocks[i].hasOwnProperty("existingData")) {
        currentBlocks[i]["existingData"] = currSavedBlock;
        this.blocks.push(currSavedBlock);
      }
    }
  };
  setTileContent(tileObj: Object, isNew: boolean, isUpdate?: boolean, isSpinner?: boolean, updateList?: boolean, selectIt: boolean = true, isDuplicate: boolean = false) {
    this.widgetTileReset(false, true, isUpdate);
    if (selectIt) {
      this.selectItem(tileObj, this.selectedLanguage);
    }
    if (isDuplicate) {
      this.selectedOriginalData = JSON.parse(JSON.stringify(tileObj));
    }
    if (isSpinner) {
      this.loaderService.display(false);
    }
    if (updateList) {
      this.tile_list.loadData();
    }
  };

  handleTileDataSelectReturn($event) {
    this.selectedData.category = $event._id || '-1';
    this.tile_list.reloadTileData();
  }
}
