import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { ContentType } from './enums';
import { Dictionary } from './interface';
import { Router } from '@angular/router';

@Injectable()
export class FormRequestService {

  private formLibrariesURL = environment.formLibrariesURL;
  public orgType = environment.orgType;
  private token: any = '';
  public orgId = undefined;
  public appId = undefined;
  public locId = undefined;
  public pageOrganization: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public currentUserSubjectChanger: BehaviorSubject<any | undefined> = new BehaviorSubject<any | undefined>(undefined);
  public currentUserSubject: BehaviorSubject<any | undefined> = new BehaviorSubject<any | undefined>(undefined);
  public appStatusSubject: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public _currentUser: any | undefined = undefined;
  set currentUser(currentUser: any | undefined) {
    if (currentUser) {
      this._currentUser = currentUser;
      let userObject: any = currentUser;
      this.setToken(userObject.token);
      this.currentUserSubject.next(userObject);
    } else {
      this._currentUser = undefined;
      this.currentUserSubject.next(undefined);
      this.token = '';
    }
  }
  get currentUser(): any | undefined {
    return this._currentUser;
  }
  public cachedObj = {};
  constructor(protected http: HttpClient, private router: Router) {
    console.log('init FormRequest Service');
    this.appStatusSubject.subscribe((data: any) => {
      if (data) {
        if (data === 'login') {
          localStorage.removeItem('currentUser');
          window.location.reload();
        }
      }
    })
    this.updateServiceData();
  }
  public updateServiceData() {
    if (localStorage.getItem('currentUser')) {
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.currentUser = currentUser;
    }
    if (localStorage.getItem('o')) {
      let orgId = JSON.parse(localStorage.getItem('o'));
      this.orgId = orgId;
    }
    if (localStorage.getItem('a')) {
      let appId = JSON.parse(localStorage.getItem('a'));
      this.appId = appId;
    }
    if (localStorage.getItem('l')) {
      let locId = JSON.parse(localStorage.getItem('l'));
      this.locId = locId;
    }
  }
  public addLanguageToURL(url: string, lang?: string): string {
    return url;
  }
  public setToken(token: any) {
    this.token = token;
  }
  public getTileLink(tileId: string, userId: string = this.currentUser._id, readOnly: boolean = true) {
    let iframeLink = environment.serverTileUrl + 'app/tile/';

    if (readOnly) {
      iframeLink = iframeLink + tileId + '/preview';
    } else {
      iframeLink = iframeLink + this.appId + '/' + userId + '/' + tileId + '/' + this.locId;
    }
    iframeLink = iframeLink + '?vertical=' + this.orgType + '&productid=' + environment.productId + '&project=' + environment.projectName;

    // console.log('iframeLink', iframeLink)
    return iframeLink;
  }
  public getMetaData(type: string, fields: any[], callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string, userType?: string, practitionerType?: string) {
    let urlStr = this.formLibrariesURL;
    urlStr = urlStr + type + '/metadata';


    if (fields) {
      urlStr = urlStr + '?fields=' + fields;

      if (userType) {
        urlStr = urlStr + '&type=' + userType
      }
    }
    else {
      if (userType) {
        urlStr = urlStr + '?type=' + userType
      }
    }
    if (practitionerType) {
      urlStr = urlStr + '&subdatatype=' + practitionerType

    }

    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {

        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public saveData(type: string, data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    if (data.hasOwnProperty('_id') && data._id) {
      let urlStr = this.formLibrariesURL;
      urlStr = urlStr + type + '/' + data._id + '/update';
      this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, 'Server Error!');
          return;
        }
        if (jsonObj) {
          if (jsonObj.status) {
            callback(jsonObj, undefined);
          } else {
            if (jsonObj.hasOwnProperty('type')) {
              this.appStatusSubject.next(jsonObj.type);
            }
            callback(undefined, jsonObj.message);
          }
        } else {
          callback(undefined, error);
        }
      }, 'POST', data);
    } else {
      let urlStr = this.formLibrariesURL;
      urlStr = urlStr + type + '/create';
      this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, 'Server Error!');
          return;
        }
        if (jsonObj) {
          if (jsonObj.status) {
            callback(jsonObj, undefined);
          } else {
            if (jsonObj.hasOwnProperty('type')) {
              this.appStatusSubject.next(jsonObj.type);
            }
            callback(undefined, jsonObj.message);
          }
        } else {
          callback(undefined, error);
        }
      }, 'POST', data);
    }
  }
  public getDataLByOrgType(datatype: string, type: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, cached: boolean = false, lang?: string) {
    if (cached) {
      if (this.cachedObj.hasOwnProperty(datatype + '/' + this.orgId + '/' + type)) {
        callback(this.cachedObj[datatype + '/' + this.orgId + '/' + type], undefined);
        return;
      }
    }
    let urlStr = this.formLibrariesURL + datatype + '/list/' + this.orgId + '/' + type;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          if (cached) {
            this.cachedObj[datatype + '/' + this.orgId + '/' + type] = jsonObj;
          }
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getDataList(type: string, conf: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, source: string = '', sourceTarget: string = '', lang?: string, userType?: string) {
    let cleanConf = this.buildSearchRequestSToAPI(conf, '');

    let urlStr = this.formLibrariesURL;
    urlStr = urlStr + source + type + '/search' + sourceTarget;

    if (userType) {
      urlStr = urlStr + '/' + userType;
    }
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', cleanConf);
  }
  public deleteSingleData(type: string, id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string, userType?: string, postObject?: any) {
    let urlStr = '';
    // console.log(type);
    let _methode = 'POST';
    urlStr = this.formLibrariesURL + type + '/' + id + '/delete?answer=true&force';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, _methode, postObject);
  }
  public getDataListSummary(type: string, conf: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, cached: boolean = false, lang?: string, userType?: string) {
    let cleanConf = this.buildSearchRequestSToAPI(conf, '');
    let urlStr = this.formLibrariesURL;
    urlStr = urlStr + type + '/search/summary';
    if (userType) {
      urlStr = urlStr + '/' + userType;
    }
    urlStr = this.addLanguageToURL(urlStr, lang);
    return this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          if (cached) {
            this.cachedObj[type] = jsonObj;
          }
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', cleanConf);
  }
  public getTileDataStructure(ids: string[], callback: (dataResponse: any | undefined, requestError: any | undefined) => void, cached: boolean = false, lang?: string, userType?: string) {
    let urlStr = this.formLibrariesURL;
    urlStr = urlStr + 'qascore/tile';
    urlStr = this.addLanguageToURL(urlStr, lang);
    let cleanConf = {
      tileIds: ids
    }
    return this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', cleanConf);
  }
  public getSingleDataOriginal(type: string, id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.formLibrariesURL + type + '/' + id;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getSingleData(type: string, id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string, subDataType?: string, useGetvalue: boolean = false) {
    let urlStr = "";

    if (subDataType === undefined) {

      urlStr = this.formLibrariesURL + type + '/' + id + '/get';
    }
    else {
      if (useGetvalue) {
        urlStr = this.formLibrariesURL + type + '/' + id + '/' + subDataType + '/getvalue';

      }
      else {
        urlStr = this.formLibrariesURL + type + '/' + id + '/' + subDataType + '/get';

      }
    }

    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getDataLByOrg(type: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, cached: boolean = false, lang?: string) {
    if (cached) {
      if (this.cachedObj.hasOwnProperty(type + '/' + this.orgId)) {
        callback(this.cachedObj[type + '/' + this.orgId], undefined);
        return;
      }
    }
    // let urlStr = this.authURL  + type;
    let urlStr = this.formLibrariesURL + type + '/list/' + this.orgId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          if (cached) {
            this.cachedObj[type + '/' + this.orgId] = jsonObj;
          }
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public saveDataNoPerfix(type: string, data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    if (data.hasOwnProperty('_id') && data._id) {
      let urlStr = this.formLibrariesURL + type + '/' + data._id;
      if (type === 'qascore') {
        urlStr = urlStr + '/update'
      }
      console.log(urlStr);
      this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, 'Server Error!');
          return;
        }
        if (jsonObj) {
          if (jsonObj.status) {
            callback(jsonObj, undefined);
          } else {
            if (jsonObj.hasOwnProperty('type')) {
              this.appStatusSubject.next(jsonObj.type);
            }
            callback(undefined, jsonObj.message);
          }
        } else {
          callback(undefined, error);
        }
      }, 'POST', data);
    } else {
      let urlStr = this.formLibrariesURL + type;
      // urlStr = this.addLanguageToURL(urlStr, lang);
      this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, 'Server Error!');
          return;
        }
        if (jsonObj) {
          if (jsonObj.status) {
            callback(jsonObj, undefined);
          } else {
            if (jsonObj.hasOwnProperty('type')) {
              this.appStatusSubject.next(jsonObj.type);
            }
            callback(undefined, jsonObj.message);
          }
        } else {
          callback(undefined, error);
        }
      }, 'POST', data);
    }
  }
  public getSingleDataNoGet(type: string, id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string, subDataType?: string) {
    let urlStr = "";

    if (subDataType === undefined) {

      urlStr = this.formLibrariesURL + type + '/' + id;
    }
    else {

      urlStr = this.formLibrariesURL + type + '/' + id + '/' + subDataType;
    }

    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getDataL(type: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, cached: boolean = false, extraId: string = '', lang?: string, subDataType?: string) {

    if (cached) {
      if (this.cachedObj.hasOwnProperty(type + '/' + extraId)) {
        callback(this.cachedObj[type + '/' + extraId], undefined);
        return;
      }
    }

    let urlStr = this.formLibrariesURL + type;
    if (subDataType === undefined) {

      urlStr = urlStr + '/list';
    }
    else {

      urlStr = urlStr + '/' + subDataType + '/list';
    }
    if (extraId) {
      urlStr = urlStr + '/' + extraId;
    }
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          if (cached) {
            this.cachedObj[type + '/' + extraId] = jsonObj;
          }
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  protected buildSearchRequestSToAPI(conf: any, token: string = '', addCustomData: boolean = true): {} {
    let searchRequestGeneric: any = {
    };
    if (conf.perpage) {
      searchRequestGeneric['count'] = conf.perpage || 10;
    }
    if (searchRequestGeneric.count === -1) {
      delete searchRequestGeneric.count;
    }
    if (conf.orderBy && conf.orderDir) {
      searchRequestGeneric['order'] = [];
      searchRequestGeneric['order'].push({ field: conf.orderBy, order: conf.orderDir });
    }
    if (conf.order) {
      searchRequestGeneric['order'] = conf.order;
    }
    let fieldList: string[] = [];
    if (conf.hasOwnProperty('fieldKeys')) {
      fieldList = conf['fieldKeys'];
    }
    if (fieldList.length > 0) {
      searchRequestGeneric['fields'] = fieldList;
    }
    if (conf.hasOwnProperty('term') && conf['term'] !== undefined) {
      searchRequestGeneric['term'] = conf['term'] || '';
    }
    if (conf.hasOwnProperty('termfields') && conf['termfields'] !== undefined) {
      searchRequestGeneric['termfields'] = conf['termfields'] || '';
    }
    let filterList = {};
    if (conf.customData && addCustomData) {
      if (Object.keys(conf.customData).length > 0) {
        for (let field of Object.keys(conf.customData)) {
          if (field)
            filterList[field] = { op: 'eq', value: conf.customData[field] };
        }
      }
    }
    if (conf.filterFieldKey) {
      for (let field of conf.filterFieldKey) {
        if (field) {
          filterList[field.field] = { op: field.op, value: field.search };
          if (field.type && field.type === 'number') {
            filterList[field.field].value = Number(filterList[field.field].value);
          }
        }
      }
    }
    if (Object.keys(filterList).length > 0) {
      searchRequestGeneric['filter'] = filterList;
    }
    if (conf.hasOwnProperty('filter')) {
      searchRequestGeneric['filter'] = conf.filter;
    }
    if (conf.hasOwnProperty('page')) {
      searchRequestGeneric['page'] = conf.page;
    }
    if (token !== '') {
      searchRequestGeneric['paginationToken'] = token;
    }
    if (conf.hasOwnProperty('include') && conf['include'] !== undefined) {
      searchRequestGeneric['include'] = conf['include'] || [];
    }
    if (conf.hasOwnProperty('exclude') && conf['exclude'] !== undefined) {
      searchRequestGeneric['exclude'] = conf['exclude'] || [];
    }
    if (conf.hasOwnProperty('organizationId') && conf['organizationId'] !== undefined) {
      searchRequestGeneric['organizationId'] = conf['organizationId'] || '';
    }
    return searchRequestGeneric;
  }
  protected jsonGetRequest(urlString: string, callback: (json?: any, error?: any) => void, params?: Dictionary) {
    if (urlString) {
      let urlComps = urlString;
      if (params) {
        for (let urlItem of Object.keys(params)) {
          urlComps += '&' + urlItem + '=' + params[urlItem];
        }
      }
      return this.jsonRequest(urlComps, callback, 'GET');
    } else {
      return;
    }
  }
  public jsonRequest(urlString: string,
    callback: (json: any, error: any) => void,
    method: string = 'POST',
    postBody: any = undefined,
    contentType: string = ContentType.JSON,
    timeout: number = 10.0,
    retry: boolean = false,
    retryFactor: number = 1.5,
    maxTimeout: number = 60.0) {
    if (urlString) {
      let url: string = urlString || '';

      let headers = {
        'Content-Type': contentType,
        'vertical': this.orgType,
        'Accept': 'application/json'
      };
      headers['project'] = environment.projectName;
      headers['vertical'] = this.orgType;
      headers['productid'] = environment.productId;
      if (this.orgId && this.orgId !== '') {
        headers['organizationid'] = this.orgId;
        headers['integratedid'] = this.orgId;
      }
      if (this.token) {
        headers['Authorization'] = this.token;
      }

      let httpOptions: any = {
        responseType: 'json',
        headers: new HttpHeaders(headers),
        method: method
      }

      let bodyString = postBody;
      if (method === 'POST') {

        bodyString = JSON.stringify(postBody);
        httpOptions['body'] = bodyString;
      }
      return this.http.request(method, url, httpOptions)
        // .pipe(map(
        //     (res: any) => {
        //       // below might need to be changed
        //       if (res.status >= 404) {
        //         window.location.reload();
        //       } else if (res.status >= 400) {
        //         callback(undefined, 'server');
        //         return;
        //       }
        //       return res;
        //     }
        //   ))
        .subscribe(
          (data) => {
            this.updateToken(data);
            callback(data, undefined);
            // console.log(url, data);
          },
          (err) => {
            if (err) {
              if (err.status >= 404) {
                // window.location.reload();
                callback(undefined, 'Refresh page');
              } else if (err.status >= 400) {
                if (this.currentUser) {
                  try {
                    let jsonErr = err.json();
                    if (jsonErr.hasOwnProperty('type') && jsonErr.type === 'login') {
                      this.appStatusSubject.next(jsonErr.type);
                      // this.logout();
                    } else {
                      callback(undefined, ' Connectivity issue.');
                    }
                  } catch (e1) {
                    try {
                      if (err.hasOwnProperty('error')) {
                        let jsonErr = err.error;
                        if (jsonErr.hasOwnProperty('type') && jsonErr.type === 'login') {
                          this.appStatusSubject.next(jsonErr.type);
                          // this.logout();
                        } else {
                          callback(undefined, ' Connectivity issue.');
                        }
                      } else {
                        callback(undefined, ' Connectivity issue.');
                      }
                    } catch (e2) {
                      callback(undefined, ' Connectivity issue.');
                    }
                  }
                }
              } else {
                callback(undefined, err);
              }
            }
          });

    } else {
      // this.logger.log('Failed to create URL');
      //console.log('Failed to create URL');
    }
  }
  public updateToken(jsonObj: any) {
    if (jsonObj && jsonObj.hasOwnProperty('token')) {
      if (jsonObj.token) {
        this.setToken(jsonObj.token);
      }
      let userObject = this.currentUser;
      if (userObject) {
        userObject['token'] = jsonObj.token;
        localStorage.setItem('currentUser', JSON.stringify(userObject));
        this.currentUserSubject.next(userObject);
        this.currentUserSubjectChanger.next(userObject);
      }
    }
  }
}
