<app-form-loading-screen *ngIf="showSelfLoader"></app-form-loading-screen>
<div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="2vh">
  <div fxFlex class="contentBox">
    <span *ngIf="loading">{{ 'Loading' | translate }}...</span>
    <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="2vh">
      <div fxFlex>
        <!-- <mat-card class="example-card" >
              <mat-card-header class="topBtns">
                <mat-card-title >{{dataTypeDisplay}}: {{selectedData.title}}
                  <button *ngIf="!isDialog" mat-raised-button (click)="toggleClick('delete')" [disabled]="!selectedData.hasOwnProperty('_id')" color="info">Delete</button>
                  <button *ngIf="!isDialog" mat-raised-button (click)="toggleClick('preview')" color="info" [disabled]="!selectedData.hasOwnProperty('_id')">Preview</button>
                  <button *ngIf="!isDialog" mat-raised-button (click)="toggleClick('duplicate')" color="info">Duplicate</button>
                  <button *ngIf="!isDialog" mat-raised-button (click)="toggleClick('save')" color="info">Save</button>
                  <button *ngIf="!isDialog" mat-raised-button (click)="toggleClick('new')" color="info">New</button>
                </mat-card-title>
              </mat-card-header>
              <mat-divider *ngIf="!isDialog"></mat-divider>
              <mat-card-content> -->
        <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="2vh">
          <div fxFlex="25">
            <mat-form-field class="full-width">
              <mat-label>{{ 'Title' | translate }}</mat-label>
              <input matInput #title name="title" [(ngModel)]="selectedData.title" [required]="true"
                placeholder="{{ 'Title' | translate }}" [errorStateMatcher]="esMatcher" max="200" maxlength="200">
              <mat-error *ngIf="selectedData.title === undefined || selectedData.title === ''">
                {{ 'Title' | translate }} is required.
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="25">
            <app-custom-select-create-autocomplete class="not-padding-input" [value]="selectedData.category"
              [orgId]="selectedOrganization" [dataType]="'category'" [dataTypeDisplay]="'Category'"
              [placeholder]="'Select / Add Category'" [useOrg]="true" [canDelete]="true"
              (onSelectReturn)="handleTileDataSelectReturn($event)" [type]="'tile'" [onBlur]="true">
            </app-custom-select-create-autocomplete>
          </div>
          <div fxFlex class="topBtns" *ngIf="hasFlagType && flagMeta">
            <mat-form-field>
              <mat-label>{{ "Flag" | translate }}</mat-label>
              <mat-select (selectionChange)="selectedData.flag = $event.value" [value]="selectedData.flag">
                <mat-option *ngFor="let itm of flagMeta.enum" [value]="itm.value">{{ itm.displayName }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="50" class="topBtns">
            <!-- <button *ngIf="!isDialog && isAdmin" color="secondary" mat-raised-button
              (click)="toggleClick('designer')">{{ 'Designer' | translate }}</button> -->
            <button *ngIf="!isDialog" color="primary" mat-raised-button (click)="toggleClick('delete')"
              [disabled]="!selectedData.hasOwnProperty('_id')">{{ 'Delete' | translate }}</button>
            <button *ngIf="!isDialog" color="primary" mat-raised-button (click)="toggleClick('preview')"
              [disabled]="!selectedData.hasOwnProperty('_id')">{{ 'Preview' | translate }}</button>
            <!-- <button *ngIf="!isDialog" color="primary" mat-raised-button (click)="toggleClick('Newpreview')" [disabled]="!selectedData.hasOwnProperty('_id')">{{ 'Preview' | translate }}</button> -->
            <button *ngIf="!isDialog" color="primary" mat-raised-button (click)="toggleClick('duplicate')">{{
              'Duplicate' | translate }}</button>
            <button *ngIf="!isDialog" color="primary" mat-raised-button (click)="toggleClick('save')">{{ 'Save' |
              translate }}</button>
            <button *ngIf="!isDialog" color="primary" mat-raised-button (click)="toggleClick('new')">{{ 'New' |
              translate }}</button>
          </div>
        </div>
        <div fxFlex style="margin-bottom: 1vh;">
          <mat-checkbox [matTooltip]="'Check to enforce submitting the sections in order' | translate"
            [(ngModel)]="selectedData.enforceOrder">{{'Enforce Section Order' |
            translate}}</mat-checkbox>
        </div>
        <!-- </mat-card-content>
            </mat-card> -->
      </div>
    </div>
    <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="2vh">
      <div fxFlex="15">
        <mat-card class="example-card widgetList">
          <mat-card-header>
            <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="0px">
              <div fxFlex="80">
                <mat-card-title>{{ 'Widgets' | translate }}</mat-card-title>
              </div>
              <div fxFlex="20">
                <button mat-icon-button align="end" style="visibility: hidden;">
                  Temp
                </button>
              </div>
            </div>
          </mat-card-header>
          <mat-divider></mat-divider>
          <mat-card-content>
            <div class='widget-basic-collapse'>
              <div id='widgetEditor' block='Text' data-placement='top'
                title="{{'Add any description in multiple paragraphs.' | translate}}" class='widget_btn'
                (click)='loadWidgets($event, "text", {}, "Editor", false, true)'>
                <!-- <div>
                      <img src='/assets/img/text_32.png' />
                    </div> -->
                <div>
                  <label>{{ 'Editor' | translate }}</label>
                </div>
              </div>
              <div id='widgetstartWrapper' block='startwrapper' data-toggle='tooltip' title='' class='widget_btn'
                (click)='loadWidgets($event, "startwrapper", {}, "Start Wrapper", false, true)'>
                <!-- <div>
                      <img  src='/assets/img/inquery.png' />
                    </div> -->
                <div>
                  <label>{{ 'Start Wrapper' | translate }}</label>
                </div>
              </div>
              <div id='widgetQuestionnaire' block='questionnaire' data-toggle='tooltip' data-toggle='tooltip'
                title="{{'Multiple questions where users can select only One or Multiple answers.  Percentages can be shown in-app.' | translate}}"
                class='widget_btn'
                (click)='loadWidgets($event, "questionnaire", {}, "Cascading Questionnaire", false, true)'>
                <!-- <div>
                      <img
                        src='/assets/img/survey.png' />
                    </div> -->
                <div>
                  <label>{{ 'Cascading Questionnaire' | translate }}</label>
                </div>
              </div>
              <div id='widgetpainlevel' block='painlevel' data-toggle='tooltip' title='Denotes pain level'
                class='widget_btn' (click)='loadWidgets($event, "painlevel", {}, "Pain Level", false, true)'>
                <!-- <div>
                      <img  src='/assets/img/inquery.png' />
                    </div> -->
                <div>
                  <label>{{ 'Pain Level' | translate }}</label>
                </div>
              </div>
              <div id='widgetsurvey' block='survey' data-toggle='tooltip'
                title='Multiple questions where users can select only One or Multiple answers.  Percentages can be shown in-app.'
                class='widget_btn' (click)='loadWidgets($event, "survey", {}, "Simple Questionnaire", false, true)'>
                <!-- <div>
                      <img  src='/assets/img/inquery.png' />
                    </div> -->
                <div>
                  <label>{{ 'Simple Questionnaire' | translate }}</label>
                </div>
              </div>
              <div id='widgetQandA' block='questions' data-toggle='tooltip' data-toggle='tooltip'
                title="{{'One or more questions requiring users to type their answers.' | translate}}"
                class='widget_btn' (click)='loadWidgets($event, "questions", {}, "Q&A", false, true)'>
                <div>
                  <label>{{ 'Q&A' | translate }}</label>
                </div>
              </div>
              <div id='widgetInquiry' block='inquiry' data-toggle='tooltip'
                title='Add Inquiry at the bottom of a Tile to allow users to send inquiries about the subject discussed in that Tile. Inquiries are directed to the email entered in the Tile.'
                class='widget_btn' (click)='loadWidgets($event, "inquiry", {}, "Inquiry", false, true)'>
                <!-- <div>
                      <img  src='/assets/img/inquery.png' />
                    </div> -->
                <div>
                  <label>{{ 'Inquiry' | translate }}</label>
                </div>
              </div>
              <div id='widgetendWrapper' block='endwrapper' data-toggle='tooltip' title='' class='widget_btn'
                (click)='loadWidgets($event, "endwrapper", {}, "End Wrapper", false, true)'>
                <!-- <div>
                      <img  src='/assets/img/inquery.png' />
                    </div> -->
                <div>
                  <label>{{ 'End Wrapper' | translate }}</label>
                </div>
              </div>
              <div id='widgetLinkdescription' block='linkDescription' data-toggle='tooltip'
                title="{{ 'Link with Description' | translate }}" class='widget_btn'
                (click)='loadWidgets($event, "linkdescription", {}, "Link with Description", false, true)'>
                <div>
                  <label>{{ 'Link with Description' | translate }}</label>
                </div>
              </div>
            </div>
          </mat-card-content>
          <mat-card-actions>
            <!-- <button mat-raised-button>LIKE</button>
                <button mat-raised-button>SHARE</button> -->
          </mat-card-actions>
        </mat-card>
      </div>
      <div fxFlex="85">
        <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="2vh">
          <div fxFlex="100" *ngIf="isDialog">
            <mat-card class="tileData">
              <mat-card-header>
                <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="0px">
                  <div fxFlex="80">
                    <mat-card-title>{{UIDisplay | translate}} {{'Data' | translate}} </mat-card-title>
                  </div>
                  <div fxFlex="20" fxLayoutAlign="end">
                    <button mat-icon-button (click)="collapseAllWidget()" *ngIf="!collapseOnload" title="Collapse All">
                      <mat-icon>unfold_less</mat-icon>
                    </button>
                    <button mat-icon-button (click)="unCollapseAllWidget()" title="Expand All" *ngIf="collapseOnload">
                      <mat-icon>unfold_more</mat-icon>
                    </button>
                  </div>
                </div>
              </mat-card-header>
              <mat-divider></mat-divider>
              <mat-card-content style="max-height: 80vh;overflow: auto;">
                <div *ngFor="let subItem of blocks;let index = index">
                  <div class="widgetAdd" [class.widgetAddSelected]="selectedIndex === index" (click)="widgetAdd(index)">
                    <!-- <div class="widgetBorder"></div> -->
                    <mat-icon class="widgetAddIcon" *ngIf="selectedIndex !== index">
                      add_circle</mat-icon>
                    <mat-icon class="widgetAddIcon" *ngIf="selectedIndex === index">cancel
                    </mat-icon>
                    <div class="widgetAddText" *ngIf="selectedIndex === index">Click on a widget to add it to this space
                    </div>
                  </div>
                  <mat-card class="example-card" *ngIf="subItem">
                    <mat-card-header [class.collapseHead]="subItem.collapse" (click)="collapseWidgetTouch(index)">
                      <!-- <div mat-card-avatar class="example-header-image"></div> -->
                      <mat-card-title>{{subItem.blockName | translate}}</mat-card-title>
                      <div fxFlex style="text-align: center;overflow: hidden;margin-top: 10px;position: relative;">
                        <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="2px"
                          *ngIf="subItem.type === 'startwrapper'">
                          <div fxFlex="80">
                            <span title="{{subItem.data && subItem.data.title ? subItem.data.title: ''}}"
                              class="startwrapperTitle" *ngIf="subItem.data && subItem.data.title">
                              {{subItem.data.title}}
                            </span>
                          </div>
                          <div fxFlex="20">
                            <span class="startwrapperColor" *ngIf="subItem.data && subItem.data.color"
                              [ngStyle]="{'background-color': subItem.data && subItem.data.color ? subItem.data.color : '#000000'}"></span>
                          </div>
                        </div>
                      </div>
                      <div fxLayoutAlign="center center">
                        <button [disabled]="index === 0" mat-icon-button (click)="moveSubData($event,true, index)">
                          <mat-icon>keyboard_arrow_up</mat-icon>
                        </button>
                        <button [disabled]="index === blocks.length-1" mat-icon-button
                          (click)="moveSubData($event,false, index)">
                          <mat-icon>keyboard_arrow_down</mat-icon>
                        </button>
                        <button mat-icon-button (click)="removeWidget($event,index)">
                          <mat-icon>delete</mat-icon>
                        </button>
                        <!-- <button mat-icon-button (click)="deleteWidget(index)" *ngIf="subItem.activate">
                          <mat-icon>visibility</mat-icon>
                        </button>
                        <button mat-icon-button (click)="unDeleteWidget(index)" *ngIf="!subItem.activate">
                          <mat-icon>visibility_off</mat-icon>
                        </button> -->
                        <button mat-icon-button (click)="collapseWidget($event, index)" *ngIf="subItem.collapse"
                          title="Expand">
                          <mat-icon>unfold_more</mat-icon>
                        </button>
                        <button mat-icon-button (click)="unCollapseWidget($event, index)" *ngIf="!subItem.collapse"
                          title="Collapse">
                          <mat-icon>unfold_less</mat-icon>
                        </button>
                      </div>
                    </mat-card-header>
                    <mat-divider></mat-divider>
                    <mat-card-content [hidden]="subItem.collapse">
                      <app-widget [isVisible]="!subItem.collapse" [title]="subItem.blockName" [type]="subItem.type"
                        [data]="subItem" (onActionData)="actionData(index, $event)"></app-widget>
                    </mat-card-content>
                  </mat-card>
                </div>
                <!-- <div class="widgetAdd" [class.widgetAddSelected]="selectedIndex == undefined"
                  (click)="widgetAdd(undefined)">
                  <mat-icon class="widgetAddIcon">
                    add_circle</mat-icon>
                </div> -->
              </mat-card-content>
            </mat-card>
          </div>
          <div fxFlex="75" *ngIf="!isDialog">
            <mat-card class="tileData">
              <mat-card-header>
                <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="0px">
                  <div fxFlex="80">
                    <mat-card-title>{{UIDisplay | translate}} {{'Data' | translate}} </mat-card-title>
                  </div>
                  <div fxFlex="20" fxLayoutAlign="end">
                    <button mat-icon-button (click)="collapseAllWidget()" *ngIf="!collapseOnload" title="Collapse All">
                      <mat-icon>unfold_less</mat-icon>
                    </button>
                    <button mat-icon-button (click)="unCollapseAllWidget()" title="Expand All" *ngIf="collapseOnload">
                      <mat-icon>unfold_more</mat-icon>
                    </button>
                  </div>
                </div>
              </mat-card-header>
              <mat-divider></mat-divider>
              <mat-card-content style="max-height: 80vh;overflow: auto;">
                <div *ngFor="let subItem of blocks;let index = index">
                  <div class="widgetAdd" [class.widgetAddSelected]="selectedIndex === index" (click)="widgetAdd(index)">
                    <!-- <div class="widgetBorder"></div> -->
                    <mat-icon class="widgetAddIcon" *ngIf="selectedIndex !== index">
                      add_circle</mat-icon>
                    <mat-icon class="widgetAddIcon" *ngIf="selectedIndex === index">cancel
                    </mat-icon>
                    <div class="widgetAddText" *ngIf="selectedIndex === index">Click on a widget to add it to this space
                    </div>
                  </div>
                  <mat-card class="example-card" *ngIf="subItem">
                    <mat-card-header [class.collapseHead]="subItem.collapse" (click)="collapseWidgetTouch(index)">
                      <!-- <div mat-card-avatar class="example-header-image"></div> -->
                      <mat-card-title>{{subItem.blockName | translate}}</mat-card-title>
                      <div fxFlex style="text-align: center;overflow: hidden;margin-top: 10px;position: relative;">
                        <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="2px"
                          *ngIf="subItem.type === 'startwrapper'">
                          <div fxFlex="80">
                            <span title="{{subItem.data && subItem.data.title ? subItem.data.title: ''}}"
                              class="startwrapperTitle" *ngIf="subItem.data && subItem.data.title">
                              {{subItem.data.title}}
                            </span>
                          </div>
                          <div fxFlex="20">
                            <span class="startwrapperColor" *ngIf="subItem.data && subItem.data.color"
                              [ngStyle]="{'background-color': subItem.data && subItem.data.color ? subItem.data.color : '#000000'}"></span>
                          </div>
                        </div>
                      </div>
                      <div fxLayoutAlign="center center">
                        <button [disabled]="index === 0" mat-icon-button (click)="moveSubData($event, true, index)">
                          <mat-icon>keyboard_arrow_up</mat-icon>
                        </button>
                        <button [disabled]="index === blocks.length-1" mat-icon-button
                          (click)="moveSubData($event, false, index)">
                          <mat-icon>keyboard_arrow_down</mat-icon>
                        </button>
                        <button mat-icon-button (click)="removeWidget($event,index)">
                          <mat-icon>delete</mat-icon>
                        </button>
                        <!-- <button mat-icon-button (click)="deleteWidget(index)" *ngIf="subItem.activate">
                          <mat-icon>visibility</mat-icon>
                        </button>
                        <button mat-icon-button (click)="unDeleteWidget(index)" *ngIf="!subItem.activate">
                          <mat-icon>visibility_off</mat-icon>
                        </button> -->
                        <button mat-icon-button (click)="collapseWidget($event, index)" *ngIf="subItem.collapse"
                          title="Expand">
                          <mat-icon>unfold_more</mat-icon>
                        </button>
                        <button mat-icon-button (click)="unCollapseWidget($event, index)" *ngIf="!subItem.collapse"
                          title="Collapse">
                          <mat-icon>unfold_less</mat-icon>
                        </button>
                      </div>
                    </mat-card-header>
                    <mat-divider></mat-divider>
                    <mat-card-content [hidden]="subItem.collapse">
                      <app-widget [isVisible]="!subItem.collapse" [title]="subItem.blockName" [type]="subItem.type"
                        [data]="subItem" (onActionData)="actionData(index, $event)"></app-widget>
                    </mat-card-content>
                  </mat-card>
                </div>
                <!-- <div class="widgetAdd" [class.widgetAddSelected]="selectedIndex == undefined"
                  (click)="widgetAdd(undefined)">
                  <div class="widgetBorder"></div>
                  <mat-icon class="widgetAddIcon">
                    add_circle</mat-icon>
                </div> -->
              </mat-card-content>
            </mat-card>
          </div>
          <div fxFlex="25" *ngIf="!isDialog" class="content-list">
            <app-tiles-list #tileList [selectedData]="selectedData" (tileAction)="tileActionReturn($event)"
              (selectItem)="selectItemCheck($event)" [hasCategories]="true" [canDelete]="true"></app-tiles-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>