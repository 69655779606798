<mat-card class="example-card tile_list" style="height: 100%;min-height: 463px">
  <mat-card-header>
    <div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="0px">
      <div fxFlex="80">
        <mat-card-title>{{UIDisplay}} List</mat-card-title>
      </div>
      <div fxFlex="20">
        <button mat-icon-button align="end" style="visibility: hidden;">
          Temp
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content style="margin-bottom: 0px;">
    <app-custom-select-create-autocomplete #subTileList [addAll]="false" [value]="selectedCategory"
      [dataType]="'category'" [dataTypeDisplay]="'Category'" [placeholder]="'Category'" [useOrg]="true"
      [canDelete]="false" (onSelectReturn)="selectCategory($event)" [type]="'tile'" [onBlur]="false"
      [orgId]="selectedOrganization">
    </app-custom-select-create-autocomplete>
    <mat-form-field>
      <input matInput #searchInput placeholder="{{'Search' | translate}}" [value]="searchVal">
    </mat-form-field>
    <mat-divider></mat-divider>
    <div style="position:relative">
      <mat-spinner [diameter]="30" *ngIf="loading" class="mat-spinner-color" color="warn" mode="indeterminate">
      </mat-spinner>
      <mat-nav-list style="min-height: 240px; max-height: 50vh; overflow-y: auto;">
        <mat-list-item *ngFor="let data of dataList" (click)="selectItemCheck(data)" class="tileListData">
          <div fxLayout="row" fxGrow="1" fxLayoutAlign="center space-around" style="width: 100%;">
            <div fxFlex="75" fxLayoutAlign="start center">
              <a class="tileName" matLine href="Javascript:void(0)" title="{{ data.title }}">{{ data.title }}</a>
            </div>
            <div fxFlex="25" fxLayoutAlign="end center">
              <button class="deleteBtn" *ngIf="canDelete" mat-icon-button (click)="delete($event, data._id)">
                <mat-icon>cancel</mat-icon>
              </button>
            </div>
          </div>
        </mat-list-item>
      </mat-nav-list>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <mat-paginator [hidePageSize]="true" [pageSize]="pageSize" [length]="paginatorTotal | async"
      [showFirstLastButtons]="true"></mat-paginator>
  </mat-card-actions>
</mat-card>